.dropdown {
    box-sizing: border-box;
    border-bottom: 1px solid #414141;
    display: flex;
    gap: 1rem;
    padding: 0.4rem 0;
}

.title {
    box-sizing: border-box;
    display: block;
    position: relative;
    letter-spacing: -.06rem;
    color: #C9C9C9;
    font-family: Inter-Bold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
    margin: 0;
}

.date {
    box-sizing: border-box;
    display: block;
    letter-spacing: -.06rem;
    color: #9E577C;
    font-family: Menlo;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
    margin-left: auto;
}
