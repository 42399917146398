.box {
    box-sizing: border-box;
    height: 122px;
    padding: 1rem;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.iconWrapper {
    width: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
 
.iconGitHub, .iconLinkedIn, .iconInstagram {
    max-width: 35%;
    max-height: 35%;
    width: auto;
    height: auto;
    transition: transform 0.3s ease;
}

.iconGitHub:hover, .iconLinkedIn:hover, .iconInstagram:hover {
    transform: scale(1.05);
}

.imageAnchor {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 939px) {
    .iconGitHub, .iconLinkedIn, .iconInstagram {
        max-width: 32%;
        max-height: 32%;
        width: auto;
        height: auto;
        transition: transform 0.3s ease;
    }
}

